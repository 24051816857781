import * as React from 'react';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Chip, CssBaseline, Stack, Grid, Tooltip, Divider, Typography, Button } from '@mui/material';
import { DeveloperBoard, Instagram, WhatsApp } from '@mui/icons-material';
import { useAppSelector } from '../../store/hooks/useAppSelector';
import { RootState } from '../../store/types/rootState';

// Custom styles
const styles = {
  container: {
    background: '#000', // Soft gradient background
    color: 'white',
    padding: '2rem 1rem',
    textAlign: 'center',
    borderTop: '1px solid #fff',
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    flexWrap: 'wrap',
  },
  chip: {
    color: 'white',
    padding: '0.8rem 1.5rem',
    cursor: 'pointer',
    borderRadius: '30px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.15)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
    },
  },
  chipIcon: {
    marginRight: '8px',
  },
  footerText: {
    marginTop: '1rem',
    fontSize: '0.9rem',
    color: 'rgba(255, 255, 255, 0.8)',
  },
  divider: {
    margin: '2rem 0',
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },
  mobileStack: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1rem',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  button: {
    backgroundColor: '#ffffff',
    color: 'green',
    padding: '0.8rem',
    borderRadius: '30px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#1de9b6',
      boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    },
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff',
    },
  },
});

const Footer = () => {
  const { useIsMobileView } = require('../../utils/responsive');
  const isMobile = useIsMobileView();

  const accountInformation = useAppSelector(({ account }: RootState) => account);

  const openWhatsAppChat = () => {
    const whatsappNumber = '+56930272184';
    const message = 'Hola, me gustaría tener mi propia página para crear mis rifas.';
    const whatsappUrl = `https://wa.me/${whatsappNumber}/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  const openSupportWhatsAppChat = () => {
    const whatsappNumber = '+56933230995';
    const message = 'Hola, necesito ayuda con mis números.';
    const whatsappUrl = `https://wa.me/${whatsappNumber}/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  const openSupportWhatsAppChat2 = () => {
    const whatsappNumber = '+584142522562';
    const message = 'Hola, necesito ayuda con mis números.';
    const whatsappUrl = `https://wa.me/${whatsappNumber}/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleClick = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={styles.container}>
        {/* Title section */}
        <Typography sx={styles.title} variant="h6">
          Conéctate con nosotros
        </Typography>
        
        {/* Divider for a clean break */}
        <Divider sx={styles.divider} />
        
        {/* Mobile layout */}
        {isMobile && accountInformation.id === 'rifas-trebol' ? (
          <Box sx={styles.mobileStack}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Tooltip title="Soporte en WhatsApp" arrow>
                <Chip
                  onClick={openSupportWhatsAppChat}
                  sx={styles.chip}
                  icon={<WhatsApp />}
                  label="Soporte"
                  variant="outlined"
                />
              </Tooltip>
              <Tooltip title="Instagram @rifastrebol1" arrow>
                <Chip
                  onClick={() => handleClick('https://www.instagram.com/rifastrebol1')}
                  sx={styles.chip}
                  icon={<Instagram />}
                  label="@rifastrebol1"
                  variant="outlined"
                />
              </Tooltip>
            </Stack>

            <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
            <Tooltip title="Soporte en WhatsApp" arrow>
                <Chip
                  onClick={openWhatsAppChat}
                  sx={styles.chip}
                  icon={<DeveloperBoard />}
                  label="Creador de la plataforma"
                  variant="outlined"
                />
              </Tooltip>
            </Stack>
          </Box>
        ) : (
          <Box sx={styles.mobileStack}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Tooltip title="Soporte en WhatsApp" arrow>
                <Chip
                  onClick={openSupportWhatsAppChat2}
                  sx={styles.chip}
                  icon={<WhatsApp />}
                  label="Soporte"
                  variant="outlined"
                />
              </Tooltip>
              <Tooltip title="Instagram @agudojhonny" arrow>
                <Chip
                  onClick={() => handleClick('https://www.instagram.com/agudojhonny')}
                  sx={styles.chip}
                  icon={<Instagram />}
                  label="@agudojhonny"
                  variant="outlined"
                />
              </Tooltip>
            </Stack>

            <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
            <Tooltip title="Soporte en WhatsApp" arrow>
                <Chip
                  onClick={openWhatsAppChat}
                  sx={styles.chip}
                  icon={<DeveloperBoard />}
                  label="Creador de la plataforma"
                  variant="outlined"
                />
              </Tooltip>
            </Stack>
          </Box>
        )}

        {/* Footer Text */}
        <Typography sx={styles.footerText}>
          © {new Date().getFullYear()} - Todos los derechos reservados.
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
